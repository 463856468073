.owl-carousel,.owl-carousel .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative
}

.owl-carousel {
    display: none;
    width: 100%;
    z-index: 1
}

.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y
}

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0
}

.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0,0,0)
}

.owl-carousel .owl-item {
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d
}

.owl-carousel .owl-dots.disabled,.owl-carousel .owl-nav.disabled {
    display: none
}

.owl-carousel .owl-dot,.owl-carousel .owl-nav .owl-next,.owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-loaded {
    display: block
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block
}

.owl-carousel.owl-hidden {
    opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
    display: none
}

.owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab
}

.owl-carousel.owl-rtl {
    direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
    float: right
}

.no-js .owl-carousel {
    display: block
}

.owl-carousel .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
    z-index: 0
}

.owl-carousel .owl-animated-out {
    z-index: 1
}

.owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.owl-height {
    transition: height .5s ease-in-out
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity .4s ease
}

.owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000
}

.owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    /* background: url(owl.video.play.png) no-repeat; */
    cursor: pointer;
    z-index: 1;
    /* -webkit-backface-visibility: hidden; */
    transition: -webkit-transform .1s ease;
    transition: transform .1s ease
}
.left-wrapper-layer {
    height: 500px;
    background: rgba(255, 255, 255, 0.8);
    position: relative;
  }
  
  .left-wrapper-content {
    margin: 0px;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding-left: 50px;
    width: 60%;
  }
  
  .left-wrapper-content h5 {
    color: var(--primary-color);
  }
  
  .left-wrapper-content p{
    margin-top: 15px
  }
  
  .left-wrapper-content .btn-default {
    margin-top: 20px;
  }
.owl-carousel .owl-video-play-icon:hover {
    -webkit-transform: scale(1.3,1.3);
    -ms-transform: scale(1.3,1.3);
    transform: scale(1.3,1.3)
}

.owl-carousel .owl-video-playing .owl-video-play-icon,.owl-carousel .owl-video-playing .owl-video-tn {
    display: none
}

.owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity .4s ease
}

.owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%
}
.service-wrapper-layer {
    background: var(--background-color);
    padding: 80px 0px;
  }
  
  .serv-box {
    text-align: center;
    padding: 25px;
  }
  
  .serv-box h4 {
    margin: 10px 0px;
  }
  
  .serv-box h6 a{
    color: var(--primary-color);
    text-decoration: none;
  }
  
  .serv-box h6 a:hover{
    color: var(--secundary-color);
    text-decoration: none;
  }
  
  .box-icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: var(--primary-color);
    position: relative;
    margin: auto;
  }
  
  .sb-icon-alt {
    width: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .counter-wrapper {
    background-image:linear-gradient(to left, rgb(0, 0, 0, 0.7), rgb(0, 0, 0, 0.7)),
    url("../images/counter-bg.jpg");
    padding: 50px 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .map-box iframe {
    border: 0;
    width: 100%;
    height: 350px;
  }
  /* .counter-wrapper:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, var(--primary-color), var(--primary-color));
    opacity: 0.8;
  } */
  
  .counter-box {
    margin: 20px 0px;
    text-align: center;
  }
  
  .counter-icon {
    width: 50px;
    margin: auto auto 15px auto;
  }
  
  .counter-box .counter {
    font-family: 'Montserrat', sans-serif;
    font-size: 40px;
    line-height: 50px;
    font-weight: 700;
    color: #eee;
  
  }
  
  .counter-box p {
    font-weight: 700;
    margin-bottom: 0px;
    color: #fff;
  }
  
  .counter-box .media-body {
    padding-left: 10px;
  }
  
  .shadow-box {
    background-color: #FFF;
    -webkit-box-shadow: 0 0 11px 0 rgba(6, 22, 58, .14);
    -moz-box-shadow: 0 0 11px 0 rgba(6, 22, 58, .14);
    box-shadow: 0 0 11px 0 rgba(6, 22, 58, .14);
    max-width: 700px;
    margin: -250px auto auto;
    z-index: 1;
    position: relative;
    padding: 50px 30px;
  }
  

.section-title {
  text-align: center;
  margin: auto auto 50px auto;
}

.section-title h2 {
  color: #DAE1E6;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 60px;
  text-align: center;
}

.section-title h3 {
  margin-top: -25px;
  text-transform: uppercase;
  z-index: 1;
  position: relative
}

.carouselImg
{
  position: relative;
}
/* =====================================
    1. SLIDER.
===================================== */
.home-slider {
  width: 100%;
  height: 850px;
  position: relative;
  z-index: 0;
}

.hero-slider {
  width: 100%;
  height: 850px;
  overflow: hidden;
}
.homeSection .carousel-inner {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

#firstSlide
{
  background: linear-gradient(to left, rgb(0, 0, 0, 0.6), rgb(0, 0, 0, 0.6)),
  url("../images/royal\ farm\ house/garden.jpg");
background-size: cover;
width: 100%;
height: 100vh;
background-repeat: no-repeat;
background-position: top top;
overflow: hidden;
}
#secondSlide
{
  background: linear-gradient(to left, rgb(0, 0, 0, 0.6), rgb(0, 0, 0, 0.6)),
  url("../images/royal\ farm\ house/gardenview.jpg");
background-size: cover;
width: 100%;
height: 100vh;
background-repeat: no-repeat;
background-position: top top;
overflow: hidden;
}
#thirdSlide
{
  background: linear-gradient(to left, rgb(0, 0, 0, 0.6), rgb(0, 0, 0, 0.6)),
  url("../images/banner.jpg");
background-size: cover;
width: 100%;
height: 100vh;
background-repeat: no-repeat;
background-position: top top;
overflow: hidden;
}
#fourthSlide
{
  background: linear-gradient(to left, rgb(0, 0, 0, 0.6), rgb(0, 0, 0, 0.6)),
  url("../images/banner.jpg");
background-size: cover;
width: 100%;
height: 100vh;
background-repeat: no-repeat;
background-position: top top;
overflow: hidden;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.6)), to(rgba(0, 0, 0, 0.6)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.hero-slider .carousel-cell {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.carousel-caption
{
  position: absolute;
    right: 5%;
    bottom: 0;
    top: 0;
    transform: translateY(50%);
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}

.slider-caption {
  position: absolute;
  
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #FFF;
  text-align: left;
}

.slider-caption .subtitle {
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 10px;
  color:peru;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.slider-caption h2 {
  font-size: 40px;
  line-height: 70px;
  width: 100%;
  color: #FFF;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media(max-width:1050px) and (min-width:900px)
{
  .carousel-caption
{
  position: absolute;
    right: 5%;
    bottom: 0;
    top: 0;
    transform: translateY(50%);
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}

.slider-caption h2 {
  font-size: 30px;
  line-height: 70px;
  width: 100%;
  color: #FFF;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 0px;
}
.slider-caption {
  position: absolute;
  
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #FFF;
  text-align: left;
}

.slider-caption .subtitle {
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 10px;
  color:peru;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.slider-caption h1 {
  font-size: 30px;
  line-height: 70px;
  width: 100%;
  color: #FFF;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 0px;
}
}

@media(max-width:499px) and (min-width:200px)
{

  .slider-caption .subtitle {
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 10px;
    color:peru;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  .slider-caption {
    position: absolute;
    
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #FFF;
    text-align:left;
  }
  .slider-caption h1 {
    font-size: 20px;
    line-height: 30px;
    width: 100%;
    color: #FFF;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .carousel-caption
{
  position: absolute;
    right: 2%;
    bottom: 0;
    top: 0;
    transform: translateY(50%);
    left: 5%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
}
.slider-caption h1 {
  font-size: 25px;
  line-height: 40px;
  width: 100%;
  color: #FFF;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 0px;
}

.slider-caption h2 {
  font-size: 25px;
  line-height: 50px;
  width: 100%;
  color: #FFF;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 0px;
}
}

.slider-caption p {
  width: 100%;
  color: #FFF;
  font-size: 18px;
  line-height: 32px;
  /* margin: 15px 0px 25px 0px; */
}

.slider-caption .btn-outline {
  background-color: transparent;
  border: solid 2px var(--primary-color);
  color: #fff;
  padding: 10px 40px;
  font-size: 15px;
  margin-top: 15px;
}

.slider-caption .btn-outline:hover {
  background-color: var(--secundary-color);
  border: solid 2px var(--secundary-color);
  color: #FFF;
  padding: 10px 40px;
  font-size: 15px;
  margin-top: 15px;
}

.hero-slider .flickity-prev-next-button {
  display: none;
}

.hero-slider .flickity-prev-next-button:hover {
  background: transparent;
}

.hero-slider .flickity-prev-next-button .arrow {
  fill: white;
}

.hero-slider .flickity-page-dots {
  display: none;
}

.hero-slider .flickity-page-dots .dot {
  width: 25px;
  height: 3px;
  opacity: 1;
  background: rgba(255, 255, 255);
  border: 0px solid white;
  border-radius: 0%;
  display: block;
  margin: 20px 0px;
}

.hero-slider .flickity-page-dots .dot.is-selected {
  width: 35px;
  background: var(--primary-color);
  border: 0px solid var(--primary-color);
}

.dot-hide .flickity-page-dots .dot {
  display: none;
}

.social-media {
  position: absolute;
  top: 25%;
  right: 30px;
  z-index: 2 !important;
}

.social-media ul li {
  margin: 50px 30px;
  font-size: 20px;
  z-index: 2;
}

.social-media ul li a {
  color: #FFF;
}

.social-media ul li a:hover {
  color: var(--secundary-color);
}
/* =====================================
    17. HOME PAGE ONE.
===================================== */
.home-about {
  padding-right: 25px;
}

.home-about h2 {
  color: #DAE1E6;
  opacity: 0.5;
  text-transform: uppercase;
  font-size: 60px;
  line-height: 70px;
}

.home-about h3 {
  /* font-size: 35px; */
  line-height: 42px;
  margin-top: -35px;
  position: relative;
  z-index: 1;
}

.home-about p{
  margin: 20px 0px;
}

.home-about-img {
  /* margin-top: -100px; */
  z-index: 1;
  position: relative;
  height:500px;
}

.home-about-img img
{
  width:100%;
  height:100%;
}
.image-badget {
  background-color:rgba(100,100,100,.9);
  padding: 30px;
  max-width: 300px;
  position: absolute;
  bottom: 15px;
  left: 15px;
  outline: 1px dashed #fff;
  outline-offset: -12px;
}

.ib-icon {
  width: 60px;
}

.image-badget h4 {
  color: #FFF;
  margin-top: 15px;
}

.image-badget p {
  color: #FFF;
}

.hb-box{
  margin: 40px 0;
}

.hb-inner{
  padding-right: 30px;
}

.hb-icon{
  width: 50px;
  height:50px;
}
.hb-icon img{
  width: 50px;
  height:100%;
}

.hb-box h5{
  font-size: 18px;
  margin: 15px 0px 2px;
}

.hb-box p{
  margin: 0px;
  font-size: 14px;
  font-style: italic;
}

.why-us-img-bg {
  background-image: url("../images/gallery/garden2.jpg");
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
@media(max-width:600px)
{
  .why-us-img-bg {
    background-image: url("../images/gallery/garden2.jpg");
    height: 50vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
}

.why-us-info {
  /* width: 60%; */
  margin: 0px;
  /* position: absolute; */
  padding-top: 20%;
  /* -ms-transform: translateY(-50%); */
  /* transform: translateY(-50%); */
  padding-left: 50px;
}
@media(max-width:500px) and (min-width:200px)
{
  .why-us-info {
    width: 100%;
    margin: 0px;
    /* position: absolute; */
    padding-top: 10%;
    /* -ms-transform: translateY(-50%); */
    /* transform: translateY(-50%); */
    padding-left: 10px;
    text-align: justify;
  }
}
.signature {
  width: 150px;
  margin: 25px 0px;
  height:100px;
}

.signature img {
  width: 150px;
  height:100%;
  /* margin: 25px 0px; */
}
.contact-bg {
  background-image: url("../images/banner.jpg");
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.map-iframe {
  width: 100%;
  height: 600px;
}

.contact-info-right {
  position: relative;
  height: 600px;
}

.badges-box {
  position: absolute;
  right: 0;
  bottom: 50px;
}

.badges-box .media .mr-3 {
  width: 60px;
}

.badges-box .media {
  background-color: var(--primary-color);
  padding: 15px 30px 15px 20px;
  outline: 1px dashed #fff;
  outline-offset: -10px;
  margin: 20px 0px;
}

.badges-box .media .media-body {
  padding: 5px 0px;
}

.badges-box .media h5 {
  margin-bottom: 0px;
  color: #FFF;
}

.badges-box .media p {
  margin-bottom: 0px;
  color: #FFF;
}

.white-bg {
  background-color: #FFF !important;
  outline: 1px dashed var(--primary-color) !important;
}

.white-bg h5 {
  color: var(--primary-heading) !important;
}

.white-bg p {
  color: var(--primary-heading) !important;
}

.contact-form {
  margin-top: 40px;
}

.contact-form .col-sm-6,
.contact-form .col-sm-12 {
  margin: 10px 0px;
}

.custom-form {
  display: block;
  width: 100%;
  height: calc(2em + 1rem + 2px);
  padding: 8px 25px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: var(--primary-color);
  background-color: #f0f2f5 !important;
  background-clip: padding-box;
  border: none;
  border-radius: 50rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: 'Open Sans', sans-serif;
}

.message-form {
  background-color: #f0f2f5 !important;
  border-radius: 10px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-form::-ms-expand {
  background-color: transparent;
  border: 0;
}

.custom-form:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-form:focus {
  color: #495057;
  background-color: #d1d9e0;
  border-color: #80bdff;
  outline: 0;
  box-shadow: none;
}

.custom-form::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.custom-form::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.custom-form:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.custom-form::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.custom-form::placeholder {
  color: #6c757d;
  opacity: 1;
}

.with-errors {
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  color: red;
  margin-top: 10px;
  margin-left: 15px;
}

.alert-success {
  color: #FFF;
  background-color: var(--secundary-color);
  border-color: var(--secundary-color);
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 1.5;
}

.close {
  float: right;
  margin-left: 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  color: #FFF;
  text-shadow: none;
  opacity: 1;
}

.news-carousel {
  position: relative;
}

.news-carousel .owl-theme .custom-nav {
  display: none;
}

.news-carousel .owl-carousel .item .news-thumbnail {
  margin: 0px 15px;
  position: relative;
}
.news-thumbnail a {
 text-decoration: none;
 color:#fff;
}


.news-carousel .owl-dots {
  display: none;
}

.news-caption {
  background-color: rgba(0, 0, 0, 0.8);
}

h6 a {
  font-size: 14px;
  text-decoration: underline;
  text-transform: uppercase;
  color:var(--primary-color);
}

.news-caption {
  padding:  20px;
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 92%;
}

.news-caption h4 {
  color: #FFF;
}

.news-caption p {
  color: #a6a6a6;
  margin: 15px 0px;
}

.read-more-layer {
  margin-top: 10px;
}

.read-more-layer h6 {
  color: var(--primary-color);
  font-size: 14px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.read-more-layer h6 a {
  text-decoration: none;
}

.right-arrow {
  width: 30px;
  margin-left: 15px;
  margin-top: -5px;
}

.news-caption .date-layer {
  position: absolute;
  top: -40px;
  right: 10px;
  background-color: var(--primary-color);
  text-align: center;
  padding: 5px;
}
.date-layer
{
  width:50px;
  height:50px;
}
.date-layer img
{
  width:100%;
  height:100%;
}
.date-layer h5 {
  font-size: 16px;
  margin-bottom: 0px;
}

.date-layer p {
  margin: -5px auto 0px;
  font-weight: 600;
  color: #FFF;
}

@media(max-width:500px) and (min-width:250px)
{
  .news-caption {
    padding:  20px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 92%;
  }
}

.services-layer {
  background-image: url("../images/farmey-5.jpg");
  padding: 80px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.services-layer-alt {
  background-color: var(--background-color);
  padding: 10px 0px 10px;
}

.services-layer .section-title h2 {
  color: #DAE1E6;
  opacity: 0.8;
  text-transform: uppercase;
  font-size: 60px;
  text-align: center;
}
@media(max-width:500px)
{
  .services-layer {
    background-image: url("../images/farmey-5.jpg");
    padding: 30px 0px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  .services-layer .section-title h2 {
    color: #DAE1E6;
    opacity: 0.8;
    text-transform: uppercase;
    font-size: 40px;
    text-align: center;
  }
}
.service-box {
  background-color: #FFF;
  -webkit-box-shadow: 0 0 11px 0 rgba(6, 22, 58, .14);
  -moz-box-shadow: 0 0 11px 0 rgba(6, 22, 58, .14);
  box-shadow: 0 0 11px 0 rgba(6, 22, 58, .14);
  border-radius: 5px;
  padding: 30px 20px;
  margin: 15px 0px;
  display: flex;
}
.media{
  width:50px;
  height:50px;
}
@media(max-width:500px) and (min-width:200px)
{
  .media{
    width:100%;
    height:50px;
  }
}
.sb-icon {
  width: 50px;
  float: left;
  margin-right:30px;
}

.sb-caption
{
  margin-left: 10px;
  text-align:left;
}
.sb-caption h4 {
  margin-bottom: 5px;
}

.sb-caption p {
  margin-bottom: 0px;
}

.service-box {
  background-color: #FFF;
  -webkit-box-shadow: 0 0 11px 0 rgba(6, 22, 58, .14);
  -moz-box-shadow: 0 0 11px 0 rgba(6, 22, 58, .14);
  box-shadow: 0 0 11px 0 rgba(6, 22, 58, .14);
  border-radius: 5px;
  padding: 30px 20px;
  margin: 15px 0px;
}

.sb-icon {
  width: 50px;
  float: left;
}

.sb-caption h4 {
  margin-bottom: 5px;
}

.sb-caption p {
  margin-bottom: 0px;
}
/* ===================================== 
    5. BUTTONS. 
===================================== */
.btn-default {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  padding: 10px 30px;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 1.3333333;
  letter-spacing: 1px;
  font-weight: 600;
  color: #FFF;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out 0s; 
  -moz-transition: all 0.3s ease-in-out 0s; 
  -ms-transition: all 0.3s ease-in-out 0s; 
  -o-transition: all 0.3s ease-in-out 0s; 
  transition: all 0.3s ease-in-out 0s; 
}

.btn-default:hover {
  color: #fff;
  background-color: var(--secundary-color);
  border-color: var(--secundary-color);
}

.btn-default:focus, .btn-default.focus {
  color: #fff;
  background-color: var(--secundary-color);
  border-color: var(--secundary-color);
  box-shadow: none;
  outline: 0;
}

.btn-default.disabled, .btn-default:disabled {
  color: #fff;
  background-color: var(--secundary-color);
  border-color: var(--secundary-color);
}

.btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  color: #fff;
  background-color: var(--secundary-color);
  border-color: var(--secundary-color);
}

.btn-default.disabled,
.btn-default:disabled {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--secundary-color);
  opacity: 1;
}
  
.btn-default-white {
  color: var(--headline-color);
  background-color: #FFF;
  border-color:  #FFF;
}

.btn-outline{
    background-color: transparent;
    border: solid 2px var(--primary-color);
    color: var(--primary-color);
}

.btn-outline-white{
  background-color: transparent; 
  border: solid 1px #FFF;
  color: #FFF;
}


.btn-lg{
  padding: 10px 35px;
  font-size: 18px;
  line-height:30px;
}

.btn-lg:hover{
  background-color: var(--secundary-color); 
  border: solid 1px var(--secundary-color); 
  color: #FFF;
}

/* =====================================
    6. TEAM.
======================================== */
.team-carousel {
  position: relative;
}

.team-carousel .owl-theme .custom-nav {
  display: none;
}

 .item .team-portrait {
 
  position: relative;
}
.team-portrait img
{ border-radius:10px;
  box-shadow:0 0 3px 5px whitesmoke;
  height:300px;
  width:100%;
  
}

.team-carousel .owl-dots {
  text-align: center;
  margin-top: 25px;
}

.team-carousel .owl-dots button.owl-dot {
  width: 15px;
  height: 15px;
  display: inline-block;
  background: #FFF;
  border: solid 1px var(--primary-color);
  margin: 0 3px;
  box-shadow: none;
  border-radius: 50%;
}

.team-carousel .owl-dots button.owl-dot.active {
  background-color: var(--primary-color);
  width: 15px;
  border: solid 1px var(--primary-color);
}

.team-carousel .owl-dots button.owl-dot:focus {
  outline: none;
}

.tp-caption {
  padding: 25px 20px;
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 250px;
  text-align: right;
}

.tp-caption:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to right bottom, transparent, #000);
  opacity: 1;
}

.tp-caption h4 {
  color: #FFF;
  margin-bottom: 0px;
  font-weight: 400;
  z-index: 1;
  position: relative;
}

.tp-caption p {
  color: var(--primary-color);
  margin-bottom: 0px;
  z-index: 1;
  position: relative;
}
/* =====================================
    7. TESTIMONIALS.
===================================== */
.testimonials-layer {
  background-image: url("../images/farmey-4.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 70px 0px;
}

.testimonials-layer:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(to bottom right, #000, #000);
  opacity: 0.5;
}

.testimonials-layer .section-title {
  margin-left: auto;
  margin-right: auto;
}

.testimonials-layer .section-title h2 {
  font-size: 35px !important;
  color: #FFF;
  opacity: 1 !important;
  position: relative;
  z-index: 1;
}

/* .testimonials-carousel {
  position: relative;
}

.testimonials-carousel .owl-theme .custom-nav {
  display: none;
}

.testimonials-carousel .owl-carousel .item .thumbnail {
  margin: 0rem 1rem;
}

.testimonials-carousel .owl-dots {
  text-align: center;
  margin-top: 25px;
}

.testimonials-carousel .owl-dots button.owl-dot {
  width: 15px;
  height: 15px;
  display: inline-block;
  background: transparent;
  border: solid 1px var(--primary-color);
  margin: 0 3px;
  box-shadow: none;
  border-radius: 50%;
}

.testimonials-carousel .owl-dots button.owl-dot.active {
  background-color: var(--primary-color);
  width: 15px;
  border: solid 1px var(--primary-color);
}

.testimonials-carousel .owl-dots button.owl-dot:focus {
  outline: none;
} */

.testimonial-box {
  width: 80%;
  text-align: center;
  position: relative;
  margin: auto;
}

.testimonial-box p {
  font-size: 18px;
  line-height: 35px;
  color: #FFF;
  text-align: center;
  margin: 20px 0px 30px 0px;
  position: relative;
  z-index: 1;
}

.testimonial-box h3 {
  color: #FFF;
  margin-top: 20px;
  margin-bottom: 0px;
}

p.profession {
  color: var(--primary-color);
  margin-top: -8px;
  margin-bottom: 0px;
}

.user-avatar {
  width: 130px;
  border-radius: 50%;
  border: solid 3px var(--primary-color);
  margin: auto;
}

.user-avatar img {
  border-radius: 50%;
}

.testimonial-box .fas {
  position: absolute;
  top: 13%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 150px;
  opacity: 0.3;
  color: var(--primary-color);
}

.testimonials-layer .section-title {
  text-align: center;
  margin: auto auto 30px auto;
}

@media(max-width:500px) and (min-width:250px)
{
  
  .testimonials-layer .section-title h2 {
    font-size: 25px !important;
  }
}


.cardImg
{
  height:200px;
}

.news-thumbnail 
{
  height:350px;
  position: relative;
}
.news-thumbnail img{
  width:100%;
  height: 100%;
}
/* ------------farms section------------------ */
.farm-img {
  background-image: url("../images/gallery/greenfarm.jpg");
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom:20px;
}
.farm-img1 {
  background-image: url("../images/gallery/royal-farm-view.jpg");
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom:20px;
}
.overlay
{
  content:"";
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  display:flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
  background-color:linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index:10;
}
.overlay h2{
  color:#fff;
  font-family: var(--secondary-heading);
}
/*====================================================
    3. PAGES HERO. 
  ====================================================*/
  .pages-hero {
    background-image: url("../images/banner.jpg");
    height: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  @media(max-width:500px) and (min-width:200px)
  {
    .pages-hero {
      background-image: url("../images/banner.jpg");
      height: 60vh;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
    }
  }
  .pages-hero:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, #000, #000);
    opacity: 0.5;
  }
  
  .pages-title {
    position: absolute;
    bottom: 60px;
    color: #FFF;
    padding-right: 20px;
  }
  
  .pages-title span {
    font-weight: 700;
    font-size: 2rem;
  }
  
  .pages-title h5 {
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 5px;
    color: peru;
    margin-bottom: 0px;
  }
  
  .pages-title h2 {
    color: #FFF;
    margin: 10px 0px 5px
  }
  
  .page-nav {
    width: 100%;
    z-index: 1;
  }
  
  .pages-title p {
    color: #FFF;
    margin-bottom: 0px;
  }
  
  .pages-hero .social-media {
    position: absolute;
    top: 25%;
    right: 30px;
    z-index: 2 !important;
  }
  
  .pages-hero .social-media ul li {
    margin: 30px;
    font-size: 16px;
    z-index: 2;
  }

  /*====================================================
    4. ABOUT US. 
  ====================================================*/
.about-info-layer {
    padding-right: 40px;
  }
  .about-img-layer {
    position: relative;
    height:400px;
  }
  .about-img-layer img {
    height:100%;
    width:100%;
  }
  .about-info-layer h2 {
    margin-bottom: 15px;
  }
  
  .about-info-layer p {
    margin: 15px 0px;
  }
  
  .more-details-layer {
    margin: 40px 0;
  }
  
  .more-details-layer h6 {
    font-style: italic;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .media-body a
  {
    color:#000;
    text-decoration: none;
  }
  .contact-layer .mr-3 {
    width: 60px;
  }

  .experience-layer {
    background-color: var( --secundary-color);
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 15px;
   
  }
  .experience-layer h4 {
   
    color: #fff!important;
  }
  h5.subtitle{
    text-align: left;
    color: #a3b0b4;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 0px;
  }
  .left-title h2 {
    font-size: 30px !important;
    line-height: 35px !important;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: left;
    color: var(--primary-heading);
    opacity: 1;
    z-index: 1;
    position: relative;
    text-transform: none !important;
    width: 70%;
  }

  @media(max-width:500px)
  {
    .about-info-layer {
      padding-right: 10px;
      text-align: justify;
    }
    .left-title h2 {
      font-size: 25px !important;
      line-height: 30px !important;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      text-align: left;
      color: var(--primary-heading);
      opacity: 1;
      z-index: 1;
      display: inline;
      position: relative;
      text-transform: none !important;
      width: 70%;
    }
  }
  .circle-icon {
    width: 70px;
    height: 70px;
    border: 2px solid var(--primary-color);
    position: relative;
    outline: 1px dashed var(--primary-color);
    outline-offset: -7px;
  }
  
  .sb-icon-alt {
    width: 40px;
    height:40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .sb-icon-alt img
  {
    width:100%;
    height:100%;
  }
  .sb-icon-alt-text
  {
    padding:20px 0;
  }
  .right-title {
    text-align: left;
    margin-top: 10px;
  }
  
/* =====================================
    15. PRODUCTS SINGLE.
======================================== */
.product-featur-img{
    outline: 5px solid var(--primary-color);
    outline-offset: -30px;
    height:400px;
  }
  
  .product-featur-img img
  {
    width:100%;
    height:100%;
  }
  .product-caption{
    margin-top: 30px;
  }
  
  .product-caption p{
    font-size: 16px;
    line-height: 30px;
  }
  
  .product-caption h3{
    margin: 30px 0px 15px;
  }
  
  .video-iframe {
    width: 100%;
    height: 500px;
    margin-top: 30px;
  }
  
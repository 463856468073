/* =====================================
    13. CONTACT US.
======================================== */
.pages-hero-contact {
    background-image: url("../images/farmey-4.jpg");
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  
  .pages-hero-contact:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom right, #000, #000);
    opacity: 0.5;
  }
  
  .contact-title {
    position: absolute;
    bottom: auto;
    bottom: 35%;
    color: #FFF;
    text-align: left !important;
  }
  
  .contact-title {
    text-align: center;
  }
  
  .contact-form {
    margin-top: 40px;
  }
  
  .contact-form .col-sm-6,
  .contact-form .col-sm-12 {
    margin: 10px 0px;
  }
  
  .map-iframe-alt {
    width: 100%;
    height: 500px;
  }
  
  .custom-form {
    display: block;
    width: 100%;
    height: calc(2em + 1rem + 2px);
    padding: 8px 25px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--primary-color);
    background-color: #f0f2f5 !important;
    background-clip: padding-box;
    border: none;
    border-radius: 50rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-family: 'Open Sans', sans-serif;
  }
  
  .message-form {
    background-color: #f0f2f5 !important;
    border-radius: 10px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .custom-form::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  
  .custom-form:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
  }
  
  .custom-form:focus {
    color: #495057;
    background-color: #d1d9e0;
    border-color: #80bdff;
    outline: 0;
    box-shadow: none;
  }
  
  .custom-form::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .custom-form::-moz-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .custom-form:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .custom-form::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .custom-form::placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .with-errors {
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    text-align: left;
    color: red;
    margin-top: 10px;
    margin-left: 15px;
  }
  
  .alert-success {
    color: #fff;
    background-color: var(--secundary-color);
    border-color: var(--secundary-color);
    font-family: 'Open Sans', sans-serif;
    font-size: 15px;
    line-height: 1.5;
  }
  
  .close {
    float: right;
    margin-left: 5px;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-shadow: none;
    opacity: 1;
  }
  
  .map-iframe-alt {
    width: 100%;
    height: 500px;
    position: relative;
  }
  
  .bottom-map {
    position: relative;
  }
  
  .contact-badge {
    margin-top: -120px;
    position: relative;
    z-index: 1;
    background: #fff;
  }
  
  .left-contact-badge {
    height: 500px;
    padding: 40px;
  }
  
  .right-contact-badge {
    background-color: var(--primary-heading);
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  
  .left-contact-badge h4 {
    margin-bottom: 25px;
  }
  
  .right-contact-badge {
    padding: 60px 40px;
    position: relative;
    overflow: hidden;
  }
  
  .right-contact-badge h4 {
    color: #fff;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
  
  .right-contact-badge p {
    color: #fff;
    margin-bottom: 20px;
  }
  .right-contact-badge p  a{
    color: #fff;
    margin-bottom: 20px;
    text-decoration: none;
  }
  
  
  .right-contact-badge p.phone::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f879";
    margin-right: 10px;
  }
  
  .right-contact-badge p.mail::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0e0";
    margin-right: 10px;
  }
  
  .right-contact-badge p.address::before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f08d";
    margin-right: 10px;
  }
  
  .photo-contact {
    width: 180px;
    position: absolute;
    bottom: -30px;
    right: -30px;
    opacity: 0.4;
  }
  
  /* =====================================
      14. PRODUCTS LIST.
  ======================================== */
  .product-description{
    background-color: #fff;
    position: relative;
    -webkit-box-shadow: 0 0 11px 0 rgba(6,22,58,.14);
    -moz-box-shadow: 0 0 11px 0 rgba(6,22,58,.14);
    box-shadow: 0 0 11px 0 rgba(6,22,58,.14);
    padding: 40px;
    margin: -130px 20px 50px 20px;
    text-align: center;
  }
  
  .icon-outline{
    outline: 2px dashed var(--primary-color);
    outline-offset: 10px;
    width: 70px;
    margin: auto auto 30px auto;
  }
  
  .product-icon{
    width: 60px;
    margin: auto;
  }
  
  .product-description .btn-default{
    margin-top: 10px;
  }
  
  
  /* =====================================
      15. PRODUCTS SINGLE.
  ======================================== */
  .product-featur-img{
    outline: 5px solid var(--primary-color);
    outline-offset: -30px;
  }
  
  .product-caption{
    margin-top: 30px;
  }
  
  .product-caption p{
    font-size: 16px;
    line-height: 30px;
  }
  
  .product-caption h3{
    margin: 30px 0px 15px;
  }
  
  .video-iframe {
    width: 100%;
    height: 500px;
    margin-top: 30px;
  }
  .footer-social ul li {
    display: inline-block;
    margin-right: 10px;
    color: #fff;
    background-color: rgba(51, 51, 51, 0.8);
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  .footer-social a{
    color:#fff;
  }
  .footer-social ul li:hover {
    background-color: var(--secundary-color);
  }
  
  .footer-social ul li:hover .fab {
    color: #fff;
  }
  .cd-top {
    display: inline-block;
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 40px;
    right: 10px;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    background: var(--secundary-color) url(../images/icons/upload.png) no-repeat center 50%;
    visibility: hidden;
    border-radius: 5px;
    z-index: 4;
  }
  